/* src/components/FloatingClientCard.css */

.floating-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .circle-button {
    background-color: #1550F0;
    border-color: #1550F0;
    box-shadow: 0 4px 12px rgba(21, 80, 240, 0.3);
  }
  
  .client-card {
    width: 300px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .close-button {
    border: none;
  }
  
  .minimize-button {
    border: none;
    color: #1550F0;
    padding: 0;
    margin-top: 10px;
  }
  