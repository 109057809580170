.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5; /* Cor de fundo */
  }
  
  .ant-spin-text {
    color: #001529; /* Cor do texto de carregamento */
    font-size: 18px;
    font-weight: 500;
  }
  